.billing-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg, #f0f9ff 0%, #e0f2fe 100%);
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  gap: 0.5rem;
}

.billing-row {
  color: #0369a1;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.608);
}

.billing-info {
  width: 100%;
  margin-bottom: 0.75rem;
  text-align: center;
}

.billing-text {
  color: #64748b;
  font-size: 2.3rem;
  margin: 0;
  font-weight: 600;
}

@media (max-width: 768px) {
  .billing-header {
    padding: 0.5rem;
  }

  .billing-row {
    font-size: 1rem;
  }
}
