/* Create Event Section Styles */
.create-event-section {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
  margin-bottom: 2rem;
}

/* Create Event Panel Container */
.create-event-panel {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

/* Form Layout for Create Event */
.create-event-panel form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

/* Form Elements: Input, Select, and Date Picker Input */
.create-event-panel input,
.create-event-panel select,
.date-picker-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  transition: all 0.2s;
  font-size: 0.875rem;
}

/* Focus State for Input and Select */
.create-event-panel input:focus,
.create-event-panel select:focus {
  outline: none;
  border-color: #0284c7;
  box-shadow: 0 0 0 3px rgba(2, 132, 199, 0.1);
}

/* Centered Input Text */
.input-centered {
  text-align: center;
}

/* Date Picker Row Layout */
.date-picker-row {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: nowrap; /* Prevents row wrapping */
}

/* Date Picker Wrapper Full Width */
.react-datepicker-wrapper {
  width: 50%; /* Fixed 50% width for each wrapper */
  min-width: 120px; /* Ensures minimal width for readability */
}

/* Date Picker Placeholder and Disabled Styles */
.react-datepicker__input-container input::placeholder {
  color: #a9a9a9;
  text-align: center;
}
.react-datepicker__input-container input:disabled {
  background-color: #f1f5f9;
}

/* Event Type Select with Custom Arrow Icon */
.event-type-select {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  text-align: center;
  width: 100%;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
}

/* ------------------------------------
   Toggles and Station Count Table
   ------------------------------------ */

/* Main grid container for toggles and station count - 2x3 table */
.toggles-station-container {
  display: grid;
  grid-template-columns: 120px 50px auto auto; /* 4 columns: label, toggle, station label, station select */
  grid-template-rows: auto auto; /* 2 rows */
  column-gap: 15px; /* Space between columns */
  row-gap: 15px; /* Space between rows */
  margin-top: 1rem;
  align-items: center;
}

/* Labels for toggles */
.toggle-label-text {
  font-size: 15px;
  font-weight: 500;
  color: #334155;
  white-space: nowrap;
  text-align: left;
}

/* Station count label */
.station-label {
  font-size: 15px;
  font-weight: 500;
  color: #334155;
  white-space: nowrap;
  text-align: left;
  margin-left: 10px; /* Add some spacing from toggle */
}

/* Toggle switch container */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  cursor: pointer;
  z-index: 1;
}

/* Hide original checkbox */
.toggle-switch input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Place above the slider for clickability */
  cursor: pointer;
  margin: 0;
  padding: 0;
  z-index: 5;
}

/* Station count select dropdown */
.station-select {
  width: 100%;
  max-width: 100px;
}

/* Inline select for compact display */
.inline-select {
  width: auto;
  min-width: 60px;
}

/* Toggle slider style */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e2e8f0;
  transition: 0.4s;
  border-radius: 25px;
  pointer-events: none;
  z-index: 1;
}
.toggle-label-text {
  cursor: pointer;
}
/* Toggle slider inner circle */
.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
  pointer-events: none;
  z-index: 2;
}

/* Toggle slider when checked */
input:checked + .slider {
  background-color: #0284c7;
}

/* Toggle slider inner circle when checked */
input:checked + .slider:before {
  transform: translateX(25px);
}

.toggle-switch label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 4;
}

/* Create Event Button Style */
.create-event-button {
  background: linear-gradient(135deg, #0ea5e9 0%, #0284c7 100%);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
  width: 100%;
  margin-top: 0.5rem;
}

/* Create Event Button Hover State */
.create-event-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(2, 132, 199, 0.2);
}

/* Create Event Row Layout */
.create-event-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.grid-row-1 {
  grid-row: 1;
}
.grid-row-2 {
  grid-row: 2;
}
.grid-col-1 {
  grid-column: 1;
}
.grid-col-2 {
  grid-column: 2;
}
.grid-col-3 {
  grid-column: 3;
}
.grid-col-4 {
  grid-column: 4;
}

.react-datepicker-popper {
  z-index: 10 !important; /* Force higher z-index to appear above toggles */
}

/* Responsive Styles for Smaller Viewports */
@media (max-width: 768px) {
  /* Adjust Padding in Create Event Section */
  .create-event-panel {
    max-width: 100%;
  }

  .date-picker-row {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  /* Adjust Panel Width */
  .create-event-panel {
    max-width: 100%;
  }

  .react-datepicker-wrapper {
    width: 50%;
  }

  /* Stack Create Event Row Elements */
  .create-event-row {
    flex-direction: column;
    gap: 1rem;
  }

  /* For small screens: keep toggle container layout */
  .toggle-container {
    justify-content: space-between;
  }

  /* Optional: For mobile column layout, replace with this code:
  .toggle-container {
    flex-direction: column;
    gap: 16px;
  }
  
  .toggle-item {
    width: 100%;
    justify-content: space-between;
  } */
}
