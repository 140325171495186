.events-panel {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 1.25rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  margin-bottom: 2rem;
}

.events-panel ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.events-header {
  display: flex;
  justify-content: space-between;
  align-items: top;
  width: 100%;
  margin-bottom: 0;
}

.redis-header {
  display: flex;
  justify-content: space-between; /* align horizontal */
  align-items: top; /* align vertical */
  width: 100%; /* full width */
  margin-bottom: 0; /* remove default margin */
}

.header-left {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  text-align: left;
  margin-left: 0;
}

.header-right {
  text-align: right;
  margin-right: 0;
}

.refresh-button {
  padding: 0.375rem;
  border-radius: 6px;
  color: #64748b;
  transition: all 0.2s;
  background: none;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
}

.refresh-button:hover {
  background: #f1f5f9;
  color: #0369a1;
  transform: rotate(180deg);
}

.events-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 6px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  transition: all 0.2s;
  cursor: pointer;
}

.events-list li:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px -1px rgba(112, 121, 219, 0.1);
  border-color: #cbd5e1;
}

.events-list li.selected {
  background-color: #eff6ff;
  border-color: #bfdbfe;
}

.event-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.event-name {
  flex: 2;
  text-align: left;
  font-size: 1rem;
  color: #334155;
}

.event-date {
  flex: 1;
  text-align: center;
  font-size: 0.9rem;
  color: #64748b;
}

.status-indicator,
.work-indicator {
  width: 9px;
  height: 9px;
  border-radius: 40%;
  margin-left: 9px;
}

.status-indicator.green {
  background: #22c55e;
  box-shadow: 0 0 0 3px rgba(34, 197, 94, 0.1);
}

.status-indicator.red {
  background: #ef4444;
  box-shadow: 0 0 0 3px rgba(239, 68, 68, 0.1);
}

.work-indicator.upcoming {
  background-color: rgb(234, 179, 8);
  box-shadow: 0 0 0 3px rgba(234, 179, 8, 0.1);
}

.work-indicator.ongoing {
  background-color: rgb(59, 130, 246);
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.work-indicator.completed {
  background-color: rgba(28, 37, 49, 0.662);
  box-shadow: 0 0 0 3px rgba(51, 65, 85, 0.1);
}

.scrollable-panel {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #f1f5f9;
  padding: 0.5rem;
  border-radius: 6px;
  background: white;
  margin-top: 0.5rem;
}

.scrollable-panel::-webkit-scrollbar {
  width: 8px;
}

.scrollable-panel::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 4px;
}

.scrollable-panel::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 4px;
}

.scrollable-panel::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

@media (max-width: 768px) {
  .events-panel {
    padding: 1rem;
  }
}
