/* Stylesheet for the Swingie Body Gallery component */
.swingie-bodygallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: var(--background-color);
  font-family: "Secular One", sans-serif;
  padding: 0 20px;
  text-align: center;
  position: relative;
}

.swingie-bodygallery-title {
  width: 100%;
  font-size: 56px;
  font-weight: bold;
  color: var(--text-color);
  background: var(--button-background-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.swingie-bodygallery-button-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  background-color: var(--background-color);
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.swingie-bodygallery-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  font-weight: bold;
  padding: 20px;
  margin: 6px;
  border-radius: 15px;
  font-size: 36px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 5px 6px 7px rgba(0, 0, 0, 0.25);
}

.swingie-bodygallery-disabled-button {
  background: var(--button-disabled-background);
  color: var(--button-disabled-text-color);
  cursor: not-allowed;
  box-shadow: none;
}

.swingie-bodygallery-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px 14px;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 100px;
}

.swingie-bodygallery-image-container {
  position: relative;
  cursor: pointer;
}

.swingie-bodygallery-image {
  /* height: 225px; */
  height: 20vh;
  width: auto;
  object-fit: cover;
}

.swingie-bodygallery-image-selected {
  outline: 4px solid var(--primary-color);
  outline-offset: -4px;
}

.swingie-bodygallery-image-overlay {
  position: absolute;
  inset: 0; /* top: 0; right: 0; bottom: 0; left: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(41, 84, 163, 0.327);
  pointer-events: none;
}
