.minimove-home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: var(--background-color);
  font-family: "Secular One", sans-serif;
  text-align: center;
  padding: 10px;
  position: relative;
}

.minimove-home-title {
  width: 90%;
  margin: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.minimove-home-header-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.minimove-home-gif-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* space between the gif and the text */
}

.minimove-home-gif-container img {
  width: 80%;
  height: auto;
  opacity: 0.88;
}

.minimove-home-remaining-text {
  text-align: center;
  font-size: 22px;
  color: var(--text-color);
  margin-bottom: 100px; /* space between the text and the button */
}

.minimove-home-start-button-container {
  position: fixed;
  bottom: 20px;
  width: 90%;
  display: flex;
  justify-content: center;
}

.minimove-home-start-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  font-weight: bold;
  padding: 15px 40px;
  border-radius: 15px;
  font-size: 36px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 5px 6px 7px rgba(0, 0, 0, 0.25);
}

.minimove-home-start-button:hover {
  transform: translateY(-2px);
}

.minimove-home-start-button:active {
  transform: translateY(2px);
}

/* RTL support */
.rtl {
  direction: rtl;
}
