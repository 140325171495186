/* Container for the Minimove Face Gallery page */
.minimove-facegallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: var(--background-color);
  font-family: "Secular One", sans-serif;
  padding: 0 20px;
  text-align: center;
  position: relative;
}

/* Fixed header with transparent background for the Minimove Face Gallery page */
.minimove-facegallery-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-color);
  z-index: 999; /* Placed just below the button container */
}

/* Responsive single-line title styling for the fixed header */
.minimove-facegallery-title {
  width: 100%;
  font-weight: bold;
  color: var(--text-color);
  background: var(--button-background-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  font-size: clamp(36px, 10vw, 96px);
  margin-top: 0px;
}

/* Timer integrated into the header */
.minimove-facegallery-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 1px;
  margin-top: 4px;
  margin-bottom: 0px;
  direction: rtl;
  opacity: 0.8;
}

/* Timer text styling */
.minimove-facegallery-timer-text {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-color);
  margin-left: 5px;
}

/* Timer clock styling with emphasis */
.minimove-facegallery-timer-clock {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-color);
}

/* Grid layout adjusted to account for the fixed header */
.minimove-facegallery-grid {
  margin-top: 10vh;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 125px;
}

/* Container for each image in the gallery */
.minimove-facegallery-image-container {
  position: relative;
}

/* Image styling */
.minimove-facegallery-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
}

/* Styling for selected images */
.minimove-facegallery-image-selected {
  border: 0px solid var(--primary-color);
}

/* Styling for images that cannot be selected */
.minimove-facegallery-image-unselectable {
  opacity: 0.5;
}

/* Overlay on images for visual effect */
.minimove-facegallery-image-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(41, 84, 163, 0.327);
  pointer-events: none;
}

/* Fixed footer button container */
.minimove-facegallery-button-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  background-color: var(--background-color);
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05);
  z-index: 1000; /* Ensures the footer is above other content */
}

/* Styling for footer buttons */
.minimove-facegallery-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  font-weight: bold;
  padding: 20px;
  margin: 6px;
  border-radius: 15px;
  font-size: 36px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 5px 6px 7px rgba(0, 0, 0, 0.25);
}

/* Disabled button styling */
.minimove-facegallery-button-disabled {
  background: var(--button-disabled-background);
  color: var(--button-disabled-text-color);
  cursor: not-allowed;
  box-shadow: none;
}

/* Animation for timer when time is running low */
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

/* Timer turns red and pulses when less than 30 seconds remain */
.minimove-facegallery-timer-clock.low-time {
  color: #ff3b30;
  animation: pulse 1s infinite;
}
