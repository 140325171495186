.redis-info {
  background: rgba(165, 203, 237, 0.05);
  padding: 1.1rem;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.redis-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.1rem;
}

.redis-title {
  font-weight: 600;
  color: #1e293b;
  font-size: 1.25rem;
}

.redis-pricing {
  text-align: right;
}

.hourly-rate {
  color: #0369a1;
  font-weight: 500;
  font-size: 0.9rem;
}

.monthly-rate {
  color: #64748b;
  font-size: 0.8rem;
  margin-top: 0.125rem;
}

.redis-memory-bar {
  display: flex;
  flex-direction: column;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  flex-wrap: nowrap;
}

.memory-usage {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
}

.memory-numbers {
  font-size: 0.9rem;
  color: #334155;
  font-weight: 500;
}

.progress-bar {
  height: 6px;
  background: #e2e8f0;
  border-radius: 3px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #0ea5e9, #0284c7);
  transition: width 0.3s ease;
}

.redis-controls {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.plan-select {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #334155;
  background: white;
  transition: all 0.2s;
}

.plan-select:hover:not(:disabled) {
  border-color: #94a3b8;
}

.plan-select:disabled {
  background-color: #f1f5f9;
  cursor: not-allowed;
}

.redis-status {
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
}

.redis-status.available {
  background-color: rgba(22, 163, 74, 0.1);
  color: rgb(22, 163, 74);
}

.redis-status.processing {
  background-color: rgba(124, 58, 237, 0.1);
  color: rgb(124, 58, 237);
}

@media (min-width: 640px) {
  .redis-memory-bar {
    /* flex-direction: row; */
    align-items: center;
  }

  .memory-usage {
    flex: 1;
  }
}
