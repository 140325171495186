.dynos-table {
  width: 100%;
  margin: 1.5rem 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
}

.dynos-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
}

.dynos-table th {
  background: #f1f5f9;
  padding: 1rem;
  font-weight: 600;
  color: #334155;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
}

.dynos-table td {
  padding: 0.75rem;
  border-top: 1px solid #e2e8f0;
  color: #475569;
  vertical-align: middle;
}

.dynos-table th:nth-child(1),
.dynos-table td:nth-child(1) {
  width: 15%; /* Process */
}

.dynos-table th:nth-child(2),
.dynos-table td:nth-child(2) {
  width: 15%; /* Dyno Count */
}

.dynos-table th:nth-child(3),
.dynos-table td:nth-child(3) {
  width: 40%; /* Plan */
}

.dynos-table th:nth-child(4),
.dynos-table td:nth-child(4) {
  width: 30%; /* Total Cost */
}

.dynos-table td:nth-child(3) {
  padding: 0.5rem;
  line-height: 1.2;
}

.dynos-table td:nth-child(3) > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.dynos-table td:nth-child(3) span:first-child {
  font-weight: 600;
  color: #1e293b;
}

.dynos-table td:nth-child(3) small {
  color: #64748b;
  font-size: 0.75rem;
  text-align: left;
  display: block;
  line-height: 1.1;
}

.dynos-table tr:nth-child(even) {
  background-color: #f8fafc;
}

@media (max-width: 768px) {
  .dynos-table {
    overflow-x: auto;
  }
}
