/* Main container for the result page */
.swingie-result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: var(--background-color);
  font-family: "Secular One", sans-serif;
  padding: 0;
  text-align: center;
  width: 100%;
}

/* כותרת מקובעת בראש העמוד */
.swingie-result-title {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 56px;
  font-weight: bold;
  color: var(--text-color);
  background: var(--button-background-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 990;
  background-color: var(--background-color);
}

/* מיכל לתמונה עם מרווחים מתאימים לכותרת ולכפתורים */
.swingie-result-image-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 120px; /* מרווח מתחת לכותרת המקובעת */
  margin-bottom: 180px; /* מרווח מוגדל מעל הכפתורים המקובעים */
  flex: 1;
  padding: 0;
}

/* התמונה עצמה - רוחב מלא */
.swingie-result-image {
  max-width: 100%; /* רוחב מלא של המסך */
  width: 100%; /* רוחב מלא */
  max-height: calc(
    100vh - 350px
  ); /* הפחתת גובה הכותרת + הכפתורים הגדולים יותר + מרווחים */
  object-fit: contain;
}

/* Container for buttons at the bottom - קיבוע לתחתית וגובה מוגדל */
.swingie-result-button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 15px 0;
  background-color: var(--background-color);
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05);
  z-index: 990;
  height: 15dvh;
}

.swingie-result-footer-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  font-weight: bold;
  padding: 20px;
  margin: 6px;
  border-radius: 15px;
  font-size: 36px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 5px 6px 7px rgba(0, 0, 0, 0.25);
}

/* WhatsApp green confirmation button */
.swingie-result-footer-button:last-child {
  background: linear-gradient(135deg, #25d366, #128c7e);
  color: white;
}

/* GIF overlay styling */
.swingie-result-gif-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.swingie-result-status-gif {
  max-width: 80%;
  max-height: 80%;
}

/* Modal overlay styling */
.swingie-result-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.swingie-result-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.swingie-result-modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
