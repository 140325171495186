.swingie-edityourcharacter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  background-color: var(--background-color);
  font-family: "Secular One", sans-serif;
  padding: 0 20px;
  text-align: center;
  position: relative;
}

.swingie-edityourcharacter-header {
  width: 100%;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: var(--text-color);
  background: var(--button-background-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.swingie-edityourcharacter-canvas-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1; /* Allow the canvas to grow and fill the available space */
  margin-top: 6%;
  margin-bottom: 14%;
}

.swingie-edityourcharacter-canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* 80% opacity */
  border: 2px solid #d1d5db;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.swingie-edityourcharacter-controls-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.swingie-edityourcharacter-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  border: none;
  padding: 12px;
  margin: 4px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.8s ease, transform 0.3s ease;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}

.swingie-edityourcharacter-button:disabled {
  background: var(--button-disabled-background);
  cursor: not-allowed;
  box-shadow: none;
}

.swingie-edityourcharacter-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 17%;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  background-color: var(--background-color);
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05);
  z-index: 1000; /* Ensure the footer is above other content */
}

.swingie-edityourcharacter-footer-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  font-weight: bold;
  padding: 20px;
  margin: 6px;
  border-radius: 15px;
  font-size: 36px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 5px 6px 7px rgba(0, 0, 0, 0.25);
}

.swingie-edityourcharacter-footer-button:disabled {
  background: var(--button-disabled-background);
  color: var(--button-disabled-text-color);
  cursor: not-allowed;
  box-shadow: none;
}

.swingie-edityourcharacter-gif-container {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1010;
}

.swingie-edityourcharacter-guide-gif {
  max-width: 300px;
  pointer-events: none;
}

.swingie-edityourcharacter-controls-container {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 10px;
  width: 100%;
}

.swingie-edityourcharacter-edit-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  border: none;
  padding: 12px;
  margin: 4px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  width: 45px;
  height: 45px;
}

.swingie-edityourcharacter-edit-button:disabled {
  background: var(--button-disabled-background);
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.6;
}

.swingie-edityourcharacter-edit-button:hover:not(:disabled) {
  transform: scale(1.05);
}

.swingie-edityourcharacter-edit-button:active:not(:disabled) {
  transform: scale(0.95);
}
