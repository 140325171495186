.resize-images-section {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
  margin-bottom: 2rem;
}

.resize-images-panel {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.resize-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  transition: all 0.2s;
  font-size: 0.875rem;
}

.resize-input:focus {
  outline: none;
  border-color: #0284c7;
  box-shadow: 0 0 0 3px rgba(2, 132, 199, 0.1);
}

.resize-button {
  background: linear-gradient(135deg, #0ea5e9 0%, #0284c7 100%);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
}

.resize-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(2, 132, 199, 0.2);
}

.resize-button:disabled {
  background: #e2e8f0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.resize-input-button-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 768px) {
  .resize-images-section {
    padding: 1rem;
  }

  .resize-images-panel {
    max-width: 100%;
  }

  .resize-button {
    width: 100%;
  }
}
