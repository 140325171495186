/* MinimoveBodyGallery.css */

/* מונע גלילה אנכית בדף כולו, כדי שיתפקד ממש כמו אפליקציה */
.no-scroll {
  overflow: hidden;
}

/* מיכל ראשי בגובה מסך מלא ללא גלילה */
.minimove-bodygallery-container {
  height: 100vh; /* ממלא את כל גובה המסך */
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  font-family: "Secular One", sans-serif;
  text-align: center;
  position: relative;
}

/* כותרת */
.minimove-bodygallery-title {
  flex-shrink: 0;
  font-size: 28px;
  margin: 10px 0;
  color: var(--text-color);
  background: var(--button-background-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* אזור ה-thumbnails למעלה */
.minimove-thumbnails-row {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  padding: 10px 0;
  height: 80px; /* גובה השורה עם המיני תמונות */
  width: 100%;
  box-sizing: border-box;
  background-color: var(--background-color);
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #888 #ccc; /* Firefox */
  z-index: 200; /* וודא שהשורה מעל הראש הנע כדי שלא יפריע ללחיצות */
}

/* עטיפת כל תמונה מוקטנת */
.minimove-thumbnail-wrapper {
  margin: 0 8px;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  flex-shrink: 0;
  position: relative;
}

/* תמונה מוקטנת */
.minimove-thumbnail-image {
  height: 50px;
  width: auto;
  object-fit: contain;
  border: 2px solid transparent;
  border-radius: 8px;
}

.minimove-thumbnail-wrapper.active {
  transform: scale(1.2);
  z-index: 210; /* להימנע מהסתרה */
}

.minimove-thumbnail-wrapper.active .minimove-thumbnail-image {
  border-color: #00eaff;
  box-shadow: 0 0 12px rgba(0, 234, 255, 0.7);
}

/* עוטף לקרוסלה על מנת לשלוט בגודל וממדים טוב יותר */
.minimove-bodygallery-slider-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* מיישר לחלק העליון במקום למרכז */
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 10vh; /* מוסיף רווח בחלק העליון של המיכל */
}

/* אזור הקרוסלה המרכזי */
.minimove-bodygallery-slider {
  width: 100%;
  max-width: 100%;
  height: auto;
  overflow: visible; /* חשוב כדי לראות את הסליידים מעבר לגבולות */
}

/* כל סלייד */
.minimove-bodygallery-slide {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* מיישר לחלק העליון של הסלייד */
  height: 100%;
  position: relative;
}

/* עטיפה לתמונה */
.image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 50vh; /* מבטיח גובה מינימלי */
}

/* התמונה הגדולה */
.minimove-bodygallery-image {
  max-height: 60vh; /* גובה מקסימלי */
  max-width: 98%; /* רוחב מקסימלי */
  margin: 0 auto; /* מרכוז אופקי */
  object-fit: contain; /* שומר על יחס גובה-רוחב */
  object-position: center bottom; /* ממקם את התמונה בחלק התחתון של הקונטיינר */
  display: block; /* חשוב עבור מרכוז נכון */
  pointer-events: none; /* מונע גרירת תמונות */
}

/* Animation for face entry - simple fade-in */
@keyframes faceAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Style for the moving face */
.moving-face {
  animation: faceAppear 0.2s ease-out forwards;
  will-change: transform, opacity; /* Performance optimization for animation */
}

/* General style for smooth transitions */
.keen-slider__slide {
  transition: opacity 0.3s ease;
}

/* מסגרת מרכזית - ממוקמת מחדש כדי להתאים לתמונות */
.center-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 85%;
  height: 65%;
  border: 4px solid rgba(181, 187, 199, 0.293);
  border-radius: 18px;
  pointer-events: none;
  z-index: 3;
  bottom: auto;
}

/* כפתורי ניווט בתחתית - משופר */
.minimove-bodygallery-buttons {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  background-color: var(--background-color);
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05);
  z-index: 1000; /* Ensures the footer is above other content */
}

.minimove-bodygallery-buttons button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  font-weight: bold;
  padding: 20px; /* פנימי גדול יותר */
  margin: 6px; /* מרווח חיצוני גדול יותר */
  border-radius: 15px;
  font-size: 36px; /* פונט גדול יותר */
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 5px 6px 7px rgba(0, 0, 0, 0.25);
  flex-grow: 1; /* גדל ביחס למיכל */
}

.finish-button .back-button {
  background: var(--button-light-background-gradient) !important;
}

.minimove-bodygallery-buttons button:hover {
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.3);
}

.minimove-bodygallery-buttons button:disabled {
  background: var(--button-disabled-background) !important;
  color: var(--button-disabled-text-color);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Styles for side navigation arrows */
.slider-nav-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  z-index: 100;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease, transform 0.2s ease;
  background: transparent;
}

.slider-nav-arrow:hover {
  opacity: 1;
}

.slider-nav-arrow:active {
  transform: translateY(-50%) scale(0.9);
}

.slider-nav-left {
  left: 15px;
}

.slider-nav-right {
  right: 15px;
}

.nav-arrow-indicator {
  width: 25px;
  height: 25px;
  transition: transform 0.2s ease;
  opacity: 0.85;
}

.nav-arrow-indicator.left {
  border-top: 3px solid var(--button-text-color);
  border-left: 3px solid var(--button-text-color);
  transform: rotate(-45deg);
}

.nav-arrow-indicator.right {
  border-top: 3px solid var(--button-text-color);
  border-right: 3px solid var(--button-text-color);
  transform: rotate(45deg);
}
