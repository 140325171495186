/* Styles for Swingie Home page */

.swingie-home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: var(--background-color);
  font-family: "Secular One", sans-serif;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.swingie-home-title {
  width: 90%;
  margin: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swingie-home-title img {
  max-width: 100%;
  height: auto;
}

.swingie-home-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.swingie-home-logo-image {
  width: 40%;
  max-width: 300px;
  height: auto;
  margin: 0 auto;
  object-fit: contain;
}

.swingie-home-remaining-text {
  text-align: center;
  font-size: 22px;
  color: var(--text-color);
  margin-top: 35px;
}

.swingie-home-content {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Background set to match the page background */
  background-color: var(--background-color);
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  padding: 20px 0;
}

.swingie-home-subtitle {
  font-size: 24px;
  color: #424242;
  margin-bottom: 2px;
}

.swingie-home-button-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
}

.swingie-home-number-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  font-weight: bold;
  padding: 20px;
  margin: 6px;
  border-radius: 15px;
  font-size: 36px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 5px 6px 7px rgba(0, 0, 0, 0.25);
}
