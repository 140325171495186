/* Base Container */
.admin-panel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #f8fafc;
  font-family: "Secular One", system-ui, -apple-system, sans-serif;
  padding: 24px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

/* Section Headers */
.section-header {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0 0 1rem 0;
  letter-spacing: -0.025em;
}

/* Heroku Status Container */
.heroku-status-container {
  width: 100%;
  margin-bottom: 2rem;
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.pricing-value {
  font-size: 0.9em;
  color: #666;
  text-align: left;
  white-space: nowrap;
}

.monthly-price {
  display: block;
  font-size: 0.85em;
  color: #888;
  margin-left: 5px;
}

/* Redis Status */
.redis-available {
  color: rgb(2, 162, 2);
  background-color: rgba(224, 247, 224, 0.95);
  padding: 8px 12px;
  border-radius: 6px;
}

.redis-processing {
  color: rgb(132, 7, 248);
  background-color: rgba(222, 203, 240, 0.941);
  padding: 8px 12px;
  border-radius: 6px;
}

/* Redis Info */
.redis-info {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
  margin-top: 1.5rem;
}

.redis-info .dyno-label {
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 8px;
  color: #334155;
}

.redis-info .dyno-value {
  font-size: 1.2em;
  color: #1e293b;
}

.redis-controls {
  display: flex;
  gap: 1rem;
  align-items: center;
  background: #f8fafc;
  padding: 1rem;
  border-radius: 6px;
  margin-top: 1rem;
}

.redis-controls select {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background: white;
  color: #334155;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.redis-controls select:hover {
  border-color: #94a3b8;
}

.redis-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-panel-container {
    padding: 16px;
  }

  .heroku-status-container {
    padding: 1rem;
  }
}
